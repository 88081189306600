import { DEFAULT_MARKET } from "#market/const";

import type {
  CountryCode,
  MeasurementUnitsEnum,
} from "@projectluna/codegen/schema";

export const formatAsDateTime = ({
  locale = DEFAULT_MARKET.locales[0].locale,
  dateTime,
}: {
  dateTime: string;
  locale?: string;
}) =>
  new Intl.DateTimeFormat(locale, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  }).format(new Date(dateTime));

export const formatAsDate = ({
  locale = DEFAULT_MARKET.locales[0].locale,
  date,
}: {
  date: string;
  locale: string;
}) => new Intl.DateTimeFormat(locale).format(new Date(date));

export const formatAsPrice = ({
  locale = DEFAULT_MARKET.locales[0].locale,
  amount = 0,
  currency = DEFAULT_MARKET.currency,
}: Partial<{
  amount: number;
  currency: string;
  locale: string;
}>) =>
  new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).format(amount);

export const formatAsPriceRange = ({
  start = 0,
  stop = 0,
  currency = DEFAULT_MARKET.currency,
  locale = DEFAULT_MARKET.locales[0].locale,
}: Partial<{
  currency: string;
  locale: string;
  start: number;
  stop: number;
}>) =>
  new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).formatRange(start, stop);

const UNIT_MAPPING: Record<MeasurementUnitsEnum, string> = {
  CUBIC_CENTIMETER: "cm³",
  CUBIC_DECIMETER: "dm³",
  CUBIC_METER: "m³ ",
  CUBIC_MILLIMETER: "mm³",
  LITER: "l",
  CM: "cm",
  M: "m",
  KM: "km",
  G: "g",
  KG: "kg",
  TONNE: "t",
  SQ_CM: "cms",
  SQ_M: "m²",
  SQ_KM: "km²",
  CUBIC_FOOT: "ft³ ",
  CUBIC_INCH: "in³ ",
  CUBIC_YARD: "yd³ ",
  QT: "qt",
  FL_OZ: "fl. oz",
  PINT: "pint",
  ACRE_IN: "acre-inch",
  ACRE_FT: "acre-ft",
  FT: "ft",
  YD: "yd",
  INCH: "in",
  OZ: "oz",
  LB: "lbs",
  SQ_FT: "ft²",
  SQ_YD: "yd²",
  SQ_INCH: "in²",
  MM: "mm",
  DM: "dm",
  SQ_DM: "dm²",
  SQ_MM: "mm²",
};

export const formatAsNumber = ({
  value,
  unit,
}: {
  unit: keyof typeof UNIT_MAPPING;
  value: number | string;
}) => `${value} ${UNIT_MAPPING[unit]}`;

export const formatAsCountry = ({
  locale = DEFAULT_MARKET.locales[0].locale,
  country,
}: {
  country: CountryCode | string;
  locale?: string;
}) => new Intl.DisplayNames(locale, { type: "region" }).of(country) as string;
