import {
  type CountryCode,
  type LanguageCodeEnum,
} from "@projectluna/codegen/schema";

import { DEFAULT_MARKET, SUPPORTED_MARKETS } from "./const";
import { type MatchedMarket } from "./types";

export const getMarketFromParam = (param: string): MatchedMarket => {
  const containsLocale = param.split("-").length > 1;

  const market =
    SUPPORTED_MARKETS.find(({ slug: countrySlug, locales }) =>
      locales.find(({ slug: localeSlug }) => {
        if (containsLocale) {
          return `${countrySlug}-${localeSlug}` === param;
        }
        return countrySlug === param;
      })
    ) || DEFAULT_MARKET;

  const locale =
    market.locales.find(({ slug }) => {
      if (containsLocale) {
        return slug === param.split("-")[1];
      }
    }) || market.locales[0];

  return { ...market, locale };
};

export const getMarket = ({
  languageCode,
  countryCode,
}: {
  countryCode: CountryCode;
  languageCode: LanguageCodeEnum;
}) => {
  const market =
    SUPPORTED_MARKETS.find(({ code, locales }) => code === countryCode) ||
    DEFAULT_MARKET;

  const locale =
    market.locales.find(({ code }) => code === languageCode) ||
    market.locales[0];

  return { ...market, locale };
};
