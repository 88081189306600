import { type Market } from "./types";

export const DEFAULT_MARKET: Market = {
  code: "PL",
  currency: "PLN",
  name: "Polska",
  slug: "pl",
  channelSlug: "pl",
  locales: [
    { name: "Polski", code: "PL", isoCode: "pl", slug: "pl", locale: "pl-PL" },
  ],
};

export const SUPPORTED_MARKETS: Market[] = [
  DEFAULT_MARKET,
  // {
  //   code: "US",
  //   currency: "USD",
  //   channelSlug: "pl",
  //   name: "USA",
  //   slug: "en",
  //   locales: [{ name: "Polski", code: "PL", slug: "us", locale: "pl-PL" }],
  // },
];
