"use client";

import { useLocalizedPaths } from "@projectluna/lib/paths/client";

import { Link } from "@/components/Link";
import { PriceRange } from "@/components/PriceRange";
import { trackActivity } from "@/lib/tracking/handlers";

import { type InjectedProps } from "../types";

import { cn } from "@/styles/lib";

export const Price = (props: InjectedProps<false>) => {
  const { product, analyticsName, variant } = props as InjectedProps<true>;
  const paths = useLocalizedPaths();

  return (
    <Link
      href={paths.product.asPath({
        slug: product.slug,
      })}
      onClick={() =>
        trackActivity.viewProductFromListing(
          analyticsName || "Product Card",
          product,
          product?.variants ?? []
        )
      }
    >
      <PriceRange
        className={cn({
          "text-xs md:text-sm": variant === "sm",
        })}
        pricing={product.pricing}
      />
    </Link>
  );
};
