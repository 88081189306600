import { type ComponentProps } from "react";

import { type MoneyFragment } from "@projectluna/codegen/graphql/fragments/generated";
import { useLocalizedFormatter } from "@projectluna/lib/intl/client";

import type { Maybe, MaybeWithoutTypename, WithHTMLProp } from "@/lib/types";

import { cn } from "@/styles/lib";

const DEFAULTS = "text-gold text-[0.938rem] font-medium";

const RegularPrice = ({ className, ...props }: ComponentProps<"p">) => (
  <p aria-label="price" className={cn(DEFAULTS, className)} {...props} />
);

const UndiscountedPrice = ({ className, ...props }: ComponentProps<"p">) => (
  <p
    aria-label="price"
    className={cn(
      DEFAULTS,
      "relative",
      "mr-1",
      "px-[2px]",
      "text-gray-400",
      "opacity-60",
      "after:vertical-center",
      "after:w-full",
      "after:h-[1px]",
      "after:content-[' ']",
      "after:top-1/2",
      "after:left-0",
      "after:block",
      "after:bg-gold-400",
      className
    )}
    {...props}
  />
);

const DiscountedPrice = ({ className, ...props }: ComponentProps<"p">) => (
  <p
    aria-label="discounted-price"
    className={cn(DEFAULTS, "text-gold", className)}
    {...props}
  />
);

type PriceProps = WithHTMLProp<"className"> & {
  hasDiscount?: Maybe<boolean>;
  onSale?: Maybe<boolean>;
  price: MaybeWithoutTypename<MoneyFragment>;
  priceUndiscounted?: MaybeWithoutTypename<MoneyFragment>;
};

const Price = ({
  price,
  priceUndiscounted,
  onSale,
  hasDiscount,
  className,
}: PriceProps) => {
  const formatter = useLocalizedFormatter();
  const formatPrice = formatter.price;

  if (!onSale && !hasDiscount) {
    /**
     * priceUndiscounted will be present for products, but not for orders therefore,
     * take one or other.
     * Do it like that since Saleor sometimes returns price in a buggy way.
     */
    return (
      <RegularPrice className={className}>
        {formatPrice({
          amount: priceUndiscounted?.amount ?? price?.amount,
          currency: priceUndiscounted?.currency ?? price?.currency,
        })}
      </RegularPrice>
    );
  }

  return (
    <span className="inline-flex">
      <UndiscountedPrice className={className}>
        {formatPrice({
          amount: priceUndiscounted?.amount,
          currency: priceUndiscounted?.currency,
        })}
      </UndiscountedPrice>
      <DiscountedPrice className={className}>
        {formatPrice({ amount: price?.amount, currency: price?.currency })}
      </DiscountedPrice>
    </span>
  );
};

Price.RegularPrice = RegularPrice;
Price.UndiscountedPrice = UndiscountedPrice;
Price.DiscountedPrice = DiscountedPrice;

export { Price, type PriceProps };
